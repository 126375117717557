/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./src/fonts/index.css";
import "typeface-merriweather";

// You can delete this file if you're not using it

if (typeof window !== "undefined") {
  // prevent the add to home screen banner
  window.addEventListener("beforeinstallprompt", e => {
    e.preventDefault();
  });
}
